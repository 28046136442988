import React from 'react'
import { FloatingLabel, Form } from 'react-bootstrap'
import { MsgInput } from '../MsgInput/MsgInput'
import { MsgInputServer } from '../MsgInput/MsgInputServer'
import { getMsgInputColor, getMsgInputServerColor } from '../../helpers/helpers';

export const InputSelect = ({
    label,
    className = '',
    name,
    values = [],
    onChange,
    placeholder,
    validation={},
    fetchData={},
    disabled,
    value
}) => {

    const handleOnchange = (e) => {

        if (validation[name])
            validation[name].required.isOk = true

        if (fetchData.data)
            fetchData.data.status = 'Success'

        onChange(e)
    }

    return (
        <>
            <FloatingLabel
                label={label}
            >
                <Form.Select
                    className={`${className} ${(validation ? getMsgInputColor(validation, name) : false) || getMsgInputServerColor(validation, name) ? 'is-invalid' : ''}`}
                    name={name}
                    onChange={handleOnchange}
                    placeholder={placeholder}
                    disabled={disabled}
                    value={value}
                    autoComplete='one-time-code'
                >
                    <option></option>
                    {
                        values.map((item) => {
                            return (
                                <option key={`${label}-${item.id || item.nombre}`} value={item.id}>{item.nombre}</option>
                            )
                        })
                    }
                </Form.Select>
                {
                    validation && <MsgInput obj={validation} name={name} />
                }
                <MsgInputServer obj={fetchData?.data} name={name} />
            </FloatingLabel>
        </>
    )
}
