import React from 'react'
import { FloatingLabel, Form } from 'react-bootstrap'
import { MsgInput } from '../MsgInput/MsgInput'
import { MsgInputServer } from '../MsgInput/MsgInputServer'
import { getMsgInputColor, getMsgInputServerColor } from '../../helpers/helpers';

export const InputText = ({
    label, className = '', name, type = 'text', value, onChange,
    placeholder, validation={}, fetchData ={}, disabled, as, style, readonly, min, onBlur
}) => {

    const handleOnchange = (e) => {

        if (validation[name])
            validation[name].required.isOk = true

        if (fetchData.data)
            fetchData.data.status = 'Success'

        if (min !== undefined && Number(e.target.value) < min)
            return

        onChange(e)
    }

    return (
        <>
            <FloatingLabel
                label={label}
            >
                <Form.Control
                    type={type}
                    as={as}
                    className={`${className} ${(validation ? getMsgInputColor(validation, name) : false) || getMsgInputServerColor(fetchData, name) ? 'is-invalid' : ''}`}
                    name={name}
                    value={value}
                    onChange={handleOnchange}
                    placeholder={placeholder}
                    disabled={disabled}
                    autoComplete='one-time-code'
                    style={style}
                    onBlur={onBlur}
                />
                {
                    validation && <MsgInput obj={validation} name={name} />
                }
                <MsgInputServer obj={fetchData?.data} name={name} />
            </FloatingLabel>
        </>
    )
}
