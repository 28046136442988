import React, { useContext, useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { AuthContext } from '../auth/AuthContext';
import { LayoutNavs } from '../components/LayoutNavs/LayoutNavs';
import { useLang } from '../language';
import { MisDatos } from '../pages/MisDatos/MisDatos';
import { HistorialResultats } from '../pages/HistorialResultats/HistorialResultats';
import { Sollicitud } from '../pages/Sollicitud/Sollicitud';
import ModalProteccionDatos from '../components/Modal/ModalProteccionDatos';
import { useFetch } from '../hooks/useFetch';

export const DashboardRoutes = ({authed}) => {

    const { user } = useContext(AuthContext);

    const Lang = useLang();

    return (
        <>
            <LayoutNavs />

            <div className="Side-end container-fluid">
                <Switch>
                    <Route
                        // exact
                        path="/sollicitud/:id?"
                        render={(props) => <Sollicitud namePage={Lang('PROCESOS')} {...props} />}
                    />

                    <Route
                        exact
                        path="/historial-resultats"
                        component={() => <HistorialResultats namePage={Lang('COLEGIOS')} />}
                    />

                    <Route
                        exact
                        path="/les-meves-dades"
                        component={() => <MisDatos />}
                    />

                    <Redirect to="/sollicitud" />
                </Switch>
            </div>
        </>
    )
}