import { faBook, faFile, faFilePdf, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Button, OverlayTrigger, Table, Tooltip } from 'react-bootstrap'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Loader } from '../../../components/Loader/Loader';
import { useFetch } from '../../../hooks/useFetch';
import { useLang } from '../../../language';

export const Solicitud = ({ item, fetchParametrosData, handleShowModalEliminarMuestra }) => {

    const [fetchSolicitudesMuestras, fetchSolicitudesMuestrasData] = useFetch('GET', `/clientes/solicitudes`)

    let history = useHistory();

    const Lang = useLang();


    const [muestras, setMuestras] = useState(false);

    const handleShowSolicitudMuestras = (id) => {

        if (!muestras) {

            fetchSolicitudesMuestras({
                id
            })

        } else {
            setMuestras()
        }
    }

    useEffect(() => {
        if (fetchSolicitudesMuestrasData.data?.data) {
            setMuestras(fetchSolicitudesMuestrasData.data?.data?.muestras)
        }
    }, [fetchSolicitudesMuestrasData])


    return (
        <>
            <tr key={item.solicitud_id}>
                <td valign='middle'>{item.solicitud_id}</td>
                {/* <td valign='middle'>{item.tipo_producto_id}</td> */}
                <td valign='middle'>{item.total_muestras}</td>
                <td valign='middle'>{item.fecha_creacion?.split(' ')?.[0]}</td>
                <td valign='middle'>{fetchParametrosData.data?.data?.estados_solicitud?.filter(itemEstado => itemEstado.id === item.estado_solicitud_id)[0].nombre}</td>
                <td valign='middle'>
                    <OverlayTrigger overlay={<Tooltip id={`tooltip-ver-muestras-${item.solicitud_id}`}>Veure mostres</Tooltip>}>
                        <span className="d-inline-block">
                            <Button variant="secondary" size='sm' className='ms-3 my-1' onClick={() => handleShowSolicitudMuestras(item.solicitud_id)}>
                                <FontAwesomeIcon icon={faBook} />
                            </Button>
                        </span>
                    </OverlayTrigger>

                    {
                        item.estado_solicitud_id !== 'analisis_finalizado' && item.estado_solicitud_id !== 'pendiente_analisis' && <OverlayTrigger overlay={<Tooltip id={`tooltip-ver-muestras-${item.solicitud_id}`}>Veure sol·licitud</Tooltip>}>
                            <span className="d-inline-block">
                                <Button variant="outline-primary" size='sm' className='ms-3 my-1' onClick={() => history.push(`/sollicitud/${item.solicitud_id}`)}>
                                    <FontAwesomeIcon icon={faFile} />
                                </Button>
                            </span>
                        </OverlayTrigger>
                    }
                    {
                        (item.estado_solicitud_id === 'borrador' || item.estado_solicitud_id === 'pendiente_pago') && <OverlayTrigger overlay={<Tooltip id={`tooltip-eliminar-${item.solicitud_id}`}>Eliminar sol·licitud</Tooltip>}>
                            <span className="d-inline-block">
                                <Button variant="default" size='sm' className='ms-3 my-1' onClick={() => handleShowModalEliminarMuestra(item.solicitud_id)}>
                                    <FontAwesomeIcon icon={faTrash} />
                                </Button>
                            </span>
                        </OverlayTrigger>
                    }
                </td>
            </tr>

            <tr className='bg-tr-detail' key={item.solicitud_id + '2'}>
                {
                    muestras &&
                    <td colSpan={7} className="ResultadoTest__body">
                        <Table bordered hover className='tableMain'>
                            <thead>
                                <tr>
                                    <th>{Lang('NUMERO_DE_LA_MUESTRA')}</th>
                                    <th>Adreça</th>
                                    <th>Pis</th>
                                    <th>Porta</th>
                                    <th>Localització de la bigueta</th>
                                    <th>Estat</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    muestras.map((item, index) => {

                                        return (
                                            <tr key={`${item.solicitud_id} ${index}`}>
                                                <td valign='middle'>{item.numero}</td>
                                                <td valign='middle'>{item.direccion}</td>
                                                <td valign='middle'>{item.piso}</td>
                                                <td valign='middle'>{item.puerta}</td>
                                                <td valign='middle'>{fetchParametrosData?.data?.data?.localizaciones_vigueta?.find(item2 => item2.id === item.localizacion_vigueta)?.nombre}</td>
                                                
                                                <td valign='middle'>{item.estado}</td>
                                                <td valign='middle' className=''>
                                                    {
                                                        item.estado === 'Anàlisi finalitzat' && <a
                                                            className="btn btn-primary btn-sm ms-3 my-1"
                                                            id="download-file"
                                                            href={item.doc_link}
                                                            target='_blank'
                                                            rel="noopener noreferrer">
                                                            <FontAwesomeIcon icon={faFilePdf} />
                                                        </a>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </td>
                }
                {
                    fetchSolicitudesMuestrasData.loading && <>
                        <td colSpan={7} className="text-center ResultadoTest__TdLoader">
                            <Loader />
                        </td>
                    </>
                }
            </tr>

        </>
    )
}
