import React from 'react';
import ReactDOM from 'react-dom';
import { Aluminosis } from './Aluminosis';

ReactDOM.render(
  <Aluminosis />,
  document.getElementById('root')
);

// if(module.hot){
//   module.hot.accept()
// }