import { Button, Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react'
import { ButtonSpinner } from '../ButtonSpinner/ButtonSpinner';

const ModalProteccionDatos = ({ showModalProteccionDatos, setShowModalProteccionDatos, handleCloseModalProteccionDatos,
    handleSubmit, fixed, fetchDataLoading }) => {

    return (
        <Modal show={showModalProteccionDatos} onHide={handleCloseModalProteccionDatos} size='lg' scrollable backdrop={fixed ? "static" : true}>

            {
                fixed ? <Modal.Header className='border-bottom-0'>
                </Modal.Header> : <Modal.Header className='border-bottom-0' closeButton>
                </Modal.Header>
            }
            <Modal.Body className='px-md-5 py-md-4 pt-0'>
                <h5 className='text-center mb-4'>INFORMACIÓ RELATIVA A LA PROTECCIÓ DE DADES DE
                    CARÀCTER PERSONAL
                </h5>
                <p>
                    El COL·LEGI D´APARELLADORS, ARQUITECTES TÈCNICS I ENGINYERS D´EDIFICACIÓ DE BARCELONA tracta les seves dades personals que ens faciliti i aquelles que siguin obtingudes de les seves relacions amb nosaltres, les quals seran incorporades a un fitxer o fitxers, responsabilitat de EL COL·LEGI D´APARELLADORS, ARQUITECTES TÈCNICS I ENGINYERS D´EDIFICACIÓ DE BARCELONA, i seran dedicades exclusivament al manteniment, compliment, desenvolupament, control i gestió del servei sol·licitat. En aquest sentit, es comunica que es realitzaran anàlisis de perfils i d’ús a partir de l’historial de serveis i activitats de l’interessat per tal de millorar i dotar de valor afegit als serveis i activitats a disposició dels usuaris.
                </p>
                <p>
                    Les dades es conservaran durant tot el període en què es mantingui la relació amb nosaltres, així com durant el termini de prescripció de les accions de responsabilitat per a l’exercici i/o defensa d’eventuals reclamacions.
                </p>
                <p>
                    Les dades tractades estan protegides amb els mitjans i sistemes tècnics necessaris per a preservar la seva confidencialitat i evitar la pèrdua, alteració i accés no autoritzat a les mateixes, d’acord amb els nivells de seguretat legalment demandats.
                </p>
                <p>
                    Drets dels afectats: en cas necessari, pot exercir els drets d’accés, rectificació, supressió, limitació, portabilitat i oposició al tractament de les dades, dirigint-se per escrit o per correu electrònic i acompanyant còpia del document oficial que us identifiqui, al COL.LEGI D´APARELLADORS, ARQUITECTES TÈCNICS I ENGINYERS D´EDIFICACIÓ DE BARCELONA, amb domicili al carrer Bon Pastor, nº 5, Cp 08021, Barcelona, o enviant un e-mail a informacio@cateb.cat, indicant en el sobre o en l’assumpte del missatge: Avís legal i de privacitat. També té dret a formular reclamació davant de l’autoritat de control, i a revocar el consentiment atorgat.
                </p>
                <p>
                    <strong>Altres dades d’interès:</strong>
                </p>

                <p>
                    <strong>Responsable:</strong> COL·LEGI D´APARELLADORS, ARQUITECTES TÈCNICS I ENGINYERS D´EDIFICACIÓ DE BARCELONA
                </p>
                <p>
                    <strong>CIF</strong>	Q0875009C
                </p>
                <p>
                    <strong>Adreça: </strong> C. Bon Pastor, 5. 08021 Barcelona. Espanya
                </p>
                <p>
                    <strong>Contacte: </strong> informacio@cateb.cat o tel. 93 240 20 60
                </p>
                <p>
                    <strong>Delegat de Protecció de Dades:</strong> Institut Qualitas d’Assessoria BCN, S.L.
                </p>
                <p>
                    <strong>Contacte del Delegat de Protecció de Dades: </strong> dpd@cateb.cat.
                </p>
                <p>
                    <strong>Legitimació: </strong> la base legal és el consentiment de l’interessat.
                </p>
                <p>
                    <strong>Destinataris: </strong> la prestació del servei requereix la comunicació de dades al laboratori que porta a terme les proves, als exclusius efectes de la gestió de les mateixes. En altres casos, les seves dades només podran ser comunicades a tercers en el supòsit que ho autoritzi expressament, així com per al compliment d’obligacions legals i/o contractuals. En aquest sentit, l’informem que, en el cas de facilitar-se dades bancàries per al pagament del servei, es procedirà a la seva comunicació a la nostra entitat financera als exclusius efectes de gestionar-ne el seu cobrament.
                </p>
                <p>
                    Si ens facilita dades de tercers, o ens les facilita en el futur, amb caràcter previ haurà d’haver informat i obtingut el consentiment de cada tercer en relació amb tot allò exposat anteriorment.
                </p>
            </Modal.Body>
            <Modal.Footer className=''>
                {
                    !fixed && <Button variant="default" onClick={handleCloseModalProteccionDatos} className='px-3'>
                        Sortir
                    </Button>
                }

                <ButtonSpinner
                    variant="primary" type="submit" className="ms-3 px-3"
                    handleSubmit={handleSubmit} fetchDataLoading={[fetchDataLoading ? fetchDataLoading.loading : false]}
                    value='Acceptar'
                />
            </Modal.Footer>
        </Modal>
    )
}

export default ModalProteccionDatos