import React from 'react'
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt, faFolder, faUser } from '@fortawesome/free-solid-svg-icons'

export const NavLinksInner = () => {

    return (
        <div className="py-4">
            <NavLink
                activeClassName="active"
                className="d-flex my-3 nav-link"
                exact
                to="/sollicitud"
            >
                <div style={{ 'width': '1.5rem' }} className="me-2">
                    <FontAwesomeIcon icon={faFileAlt} />
                </div>
                Sol·licitud de proves
            </NavLink>
            <NavLink
                activeClassName="active"
                className="d-flex my-3 nav-link"
                exact
                to="/historial-resultats"
            >
                <div style={{ 'width': '1.5rem' }} className="me-2">
                    <FontAwesomeIcon icon={faFolder} />
                </div>
                Historial de resultats
            </NavLink>
            <NavLink
                activeClassName="active"
                className="d-flex my-3 nav-link"
                exact
                to="/les-meves-dades"
            >
                <div style={{ 'width': '1.5rem' }} className="me-2">
                    <FontAwesomeIcon icon={faUser} />
                </div>
                Les meves dades
            </NavLink>

        </div>
    )
}
