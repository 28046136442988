import React, { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { ButtonSpinner } from '../../../../components/ButtonSpinner/ButtonSpinner'
import { useFetch } from '../../../../hooks/useFetch'

export const ModalCopiarDatos = ({ showModalCopiarDatos, handleCloseModalCopiarDatos, solicitud_id, fetchSolicitudesMuestras, setMuestras }) => {

    const [fetchCopiarMuestra, fetchCopiarMuestraData] = useFetch('PUT', `/clientes/muestras`)

    const handleCopiarMuestra = (e) => {

        e.preventDefault()

        fetchCopiarMuestra({
            id: showModalCopiarDatos.form.id,
            body: {
                ...showModalCopiarDatos.form,
                vigueta_fisurada: (showModalCopiarDatos.form.vigueta_fisurada === 'true'),
                vigueta_polvo_blanquinoso: (showModalCopiarDatos.form.vigueta_polvo_blanquinoso === 'true'),
                vigueta_corrosion_visible: (showModalCopiarDatos.form.vigueta_corrosion_visible === 'true'),
                vigueta_oxido_adherido: (showModalCopiarDatos.form.vigueta_oxido_adherido === 'true'),
                vigueta_descripcion_fisura_altres: showModalCopiarDatos.form.vigueta_descripcion_fisura_altres || 'buit',
                pais: 'España',
                copiar_a_vacia: true
            }
        })
    }

    useEffect(() => {

        if (fetchCopiarMuestraData.data?.status === 'Success') {

            handleCloseModalCopiarDatos()
        }

    }, [fetchCopiarMuestraData])

    // useEffect(() => {

    //     if (!showModalCopiarDatos.value) {

    //         setMuestras([])
            
    //         if (solicitud_id) {
    //             console.log('fetchSolicitudesMuestras en modal copiar')
    //             fetchSolicitudesMuestras({
    //                 id: solicitud_id
    //             })
    //         }
    //     }
    // }, [showModalCopiarDatos.value])


    return (
        <>
            <Modal show={showModalCopiarDatos.value} onHide={handleCloseModalCopiarDatos} size='md' id='ModalCopiarDatos'>
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title className='h5'></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 mb-4 mt-3">
                                <h5 className='text-center'>
                                    Si les següents mostres són de la mateixa finca o habitatge, pots copiar automàticament les dades i després revisar-les
                                </h5>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='border-0'>
                    <Button variant="default" onClick={() => {
                        handleCloseModalCopiarDatos()
                    }}>
                        No copiar dades
                    </Button>
                    <ButtonSpinner
                        variant="primary" type="submit" className="ms-2"
                        handleSubmit={handleCopiarMuestra} fetchDataLoading={[fetchCopiarMuestraData.loading]}
                        value='Copiar dades'
                    />
                </Modal.Footer>
            </Modal>
        </>
    )
}
