import { faArrowLeft, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Alert, Button, FloatingLabel, Form, Modal } from 'react-bootstrap';
import { AuthContext } from '../../../../auth/AuthContext';
import { ButtonSpinner } from '../../../../components/ButtonSpinner/ButtonSpinner';
import { InputRadios } from '../../../../components/Inputs/InputRadios';
import { Loader } from '../../../../components/Loader/Loader';
import { useFetch } from '../../../../hooks/useFetch';
import { useForm } from '../../../../hooks/useForm';
import { useValidator } from '../../../../hooks/useValidator';
import { ModalCambiarPagador } from './ModalCambiarPagador';
import { ModalPagarPresencialmente } from './ModalPagarPresencialmente';

export const FacturacionPago = ({ setIndexFocusWizard, muestras, setMuestras, solicitud_id, indexFocusWizard,
    fetchSolicitudesMuestras, fetchSolicitudesMuestrasData, fetchParametrosData }) => {

    const [fetchMisDatos, fetchMisDatosData] = useFetch('GET', `/clientes/usuarios/me`)

    const [fetchPrecios, fetchPreciosData] = useFetch('GET', `/clientes/parametros`)

    const [formMisDatos, handleInputChangeMisDatos, handleFormChangeMisDatos] = useForm({
        usuario_id: '',
        usuario_confirmado: '',
        usuario_nombre: '',
        usuario_apellidos: '',
        usuario_tipo_doc_id: '',
        usuario_documento: '',
        usuario_email: '',
        usuario_telefono: '',
        usuario_num_colegiado: '',
        usuario_fecha_creacion: '',
        usuario_fecha_modificacion: '',
        datos_fact: {}
    })

    const [formFacturacion, handleInputChangeFacturacion, handleFormChangeFacturacion] = useForm({
        id: -1,
        es_empresa: false,
        tipo_doc_id: '',
        documento: '',
        nombre: '',
        direccion: '',
        provincia: '',
        poblacion: '',
        codigo_postal: -1,
        num_cliente: -1,
        pais: '',
        fecha_creacion: '',
        fecha_modificacion: ''
    })


    useEffect(() => {
        fetchMisDatos()
        fetchPrecios()
    }, [])

    useEffect(() => {
        if (fetchMisDatosData.data) {

            console.log('fetchMisDatosData', fetchMisDatosData)

            handleFormChangeMisDatos({
                ...fetchMisDatosData.data?.data
            })
        }
    }, [fetchMisDatosData])




    // Seccion 3 - Facturacion y pago

    const [pagador, setPagador] = useState(0);

    const handleChangePagador = (e) => {
        setPagador(Number(e.target.value))
    }

    // Cambiar pagador

    const [showModalCambiarPagador, setShowModalCambiarPagador] = useState(false);

    const handleCloseModalCambiarPagador = () => {
        setPagador(0)
        setShowModalCambiarPagador(false)
    };
    const handleShowModalCambiarPagador = () => setShowModalCambiarPagador(true);

    const [documento, setDocumento] = useState(1);

    const handleCambiarPagador = () => {

        fetchCambiarPagador({
            id: `${solicitud_id}/cambiar_pagador`,
            body: {
                documento: documento
            }
        })
    }

    useEffect(() => {

        if (pagador === 1) {
            setDocumento(fetchSolicitudesMuestrasData?.data?.data?.tramitador?.usuario_documento)
        }

    }, [pagador])

    const [fetchCambiarPagador, fetchCambiarPagadorData] = useFetch('POST', `/clientes/solicitudes`)

    const handleChangeAMisDatosFacturacion = () => {

        fetchCambiarPagador({
            id: `${solicitud_id}/cambiar_pagador`,
            body: {
                documento: formMisDatos.datos_fact?.documento,
                // nombre_completo: formMisDatos.usuario_nombre + ' ' + formMisDatos.usuario_apellidos,
                // direccion: formMisDatos.datos_fact?.direccion,
                // provincia: formMisDatos.datos_fact?.provincia,
                // poblacion: formMisDatos.datos_fact?.poblacion,
                // codigo_postal: formMisDatos.datos_fact?.codigo_postal,
                // pais: formMisDatos.datos_fact?.pais,
                // email: formMisDatos.usuario_email
            }
        })
    }

    useEffect(() => {

        if (fetchSolicitudesMuestrasData.data) {

            handleFormChangeFacturacion({
                ...fetchSolicitudesMuestrasData.data?.data?.datos_facturacion
            })
        }

    }, [fetchSolicitudesMuestrasData])


    // Actualizar precio

    const [precio, setPrecio] = useState(0);

    useEffect(() => {

        if (formMisDatos && fetchPreciosData.data && fetchSolicitudesMuestrasData.data) {

            console.log(fetchSolicitudesMuestrasData.data)

            const tipo_producto_id = fetchSolicitudesMuestrasData.data?.data?.tipo_producto_id

            const precios = fetchPreciosData.data?.data?.precios

            let index = tipo_producto_id

            if (formMisDatos.usuario_num_colegiado) {
                index += '_colegiado'
            } else {
                index += '_no_colegiado'
            }

            console.log(index)

            const precio = precios?.find(item => item.id === index)?.precio_unidad

            setPrecio(precio)
        }

    }, [fetchPreciosData, formMisDatos, fetchSolicitudesMuestrasData])

    // Pagar

    const [fetchPagar, fetchPagarData] = useFetch('POST', `/clientes/solicitudes`, 'text')

    const { user, dispatch } = useContext(AuthContext);

    const handlePagar = () => {

        fetchPagar({
            id: `${solicitud_id}/pagar`,
            body: {
                token: user.token,
                url_redireccion_ok: `${process.env.PUBLIC_URL}/sollicitud/${solicitud_id}`,
                url_redireccion_ko: `${process.env.PUBLIC_URL}/sollicitud/${solicitud_id}`,
            }
        })
    }

    const [dataHtml, setdataHtml] = useState('')

    useEffect(() => {

        if (fetchPagarData.data) {

            setdataHtml(fetchPagarData.data)
        }

    }, [fetchPagarData])


    // Generar factura

    const [fetchGenerarFactura, fetchGenerarFacturaData] = useFetch('POST', `/clientes/solicitudes`)

    const [formGenerarFactura, handleInputChangeGenerarFactura, handleFormChangeGenerarFactura] = useForm({
        via_pago: '',
    })

    const [valuesValidGenerarFactura, handleVerifiedValuesGenerarFactura] = useValidator({

        via_pago: {
            required: {
                isOk: true,
                msgError: [`La via de pagament és obligatòria.`]
            }
        },
    })


    const handleGenerarFactura = () => {

        if (handleVerifiedValuesGenerarFactura(formGenerarFactura)) {

            fetchGenerarFactura({
                id: solicitud_id + '/generar_factura',
                body: {
                    // tipo_producto: fetchSolicitudesMuestrasData.data?.data?.tipo_producto_id,
                    via_pago: formGenerarFactura.via_pago
                }
            })
        }
    }

    useEffect(() => {

        if (fetchGenerarFacturaData.data?.status === 'Success') {
            setIndexFocusWizard(indexFocusWizard + 1);
        }

    }, [fetchGenerarFacturaData])


    // Nuevo Pagador

    const [showModalNuevoPagador, setShowModalNuevoPagador] = useState(false);

    const handleCloseModalNuevoPagador = () => setShowModalNuevoPagador(false)
    const handleShowModalNuevoPagador = () => setShowModalNuevoPagador(true);

    useEffect(() => {

        // Si da error, no existe el documento, entonces abrir la segunda modal

        if (fetchCambiarPagadorData.data?.status === 'Error') {

            setShowModalCambiarPagador(false)
            setShowModalNuevoPagador(true)

        }

        // Si no da error, el documento ya ha sido actualizado, 
        // solo queda recargar los datos y cerrar la modal

        else if (fetchCambiarPagadorData.data?.status === 'Success') {

            if (solicitud_id) {

                setMuestras([])

                fetchSolicitudesMuestras({
                    id: solicitud_id
                })
            }

            setShowModalCambiarPagador(false)
        }

    }, [fetchCambiarPagadorData])

    const inputEl = useRef(null);

    useEffect(() => {

        if (dataHtml) {

            if (inputEl.current) {
                try {

                    inputEl.current.children[0].submit()

                } catch (error) {

                    console.log(error);
                }
            }
        }

    }, [dataHtml])

    const handleMiddlePagar = _ => {

        console.log(formGenerarFactura.via_pago);
        if (formGenerarFactura.via_pago === 'visa') {

            handlePagar()

        } else {

            handleGenerarFactura()
        }
    }

    // Pagar presencialmente

    const [showModalPagarPresencialmente, setShowModalPagarPresencialmente] = useState(false);

    const handleCloseModalPagarPresencialmente = () => setShowModalPagarPresencialmente(false);
    const handleShowModalPagarPresencialmente = () => setShowModalPagarPresencialmente(true);

    // Cambiar a borrador

    const [fetchEditarEstadoSolicitud, fetchEditarEstadoSolicitudData] = useFetch('PATCH', `/clientes/solicitudes`)

    const handleClickCambiarABorrador = _ => {

        fetchEditarEstadoSolicitud({
            id: `${solicitud_id}/estado`,
            body: {
                estado_id: 'borrador'
            }
        })
    }

    useEffect(() => {

        if (fetchEditarEstadoSolicitudData.data?.status === 'Success') {
            setIndexFocusWizard(indexFocusWizard - 1);
        }

    }, [fetchEditarEstadoSolicitudData])

    // ---------- POST al obtener  el documento de tramitacion

    const [fetchCambiarPagadorPOST, fetchCambiarPagadorPOSTData] = useFetch('POST', `/clientes/solicitudes`)

    useEffect(() => {

        if (formFacturacion.documento) {

            fetchCambiarPagadorPOST({
                id: `${solicitud_id}/cambiar_pagador`,
                body: {
                    documento: formFacturacion.documento
                }
            })
        }
    }, [formFacturacion.documento])


    useEffect(() => {

        if (fetchCambiarPagadorPOSTData.data) {

            console.log('domiciliacion_disponible', fetchCambiarPagadorPOSTData?.data?.data?.domiciliacion_disponible);

            if (fetchCambiarPagadorPOSTData?.data?.data?.domiciliacion_disponible) {
                handleFormChangeGenerarFactura({
                    via_pago: 'domiciliacion'
                })
            } else {
                handleFormChangeGenerarFactura({
                    via_pago: 'visa'
                })
            }

        }
        
    }, [fetchCambiarPagadorPOSTData])
    
    useEffect(() => {
        
        console.log('formGenerarFactura', formGenerarFactura);
    
    }, [formGenerarFactura]);

    const [valuesViaPago, setvaluesViaPago] = useState([]);
    
    useEffect(() => {
      if (fetchCambiarPagadorPOSTData?.data && fetchPreciosData.data) {
        
        if (fetchCambiarPagadorPOSTData?.data?.data?.domiciliacion_disponible) {
            setvaluesViaPago(fetchPreciosData.data?.data?.vias_de_pago || [])
        } else {
            setvaluesViaPago([{ id: 'visa', nombre: 'Visa' }])
        }
      } else {
        setvaluesViaPago([])
      }
    }, [fetchCambiarPagadorPOSTData, fetchPreciosData]);
    

    return (
        <>
            <div className="container animate__faster animate__animated animate__fadeInLeft">
                <div className="row py-4 px-3">
                    <div className="col-12">
                        <h5 className='mb-4 text-center'>Dades de facturació</h5>
                    </div>

                    <div className="d-flex justify-content-center flex-column align-items-center">
                        <div className="col-md-9 col-lg-7 col-xl-6 mb-2">
                            {
                                fetchSolicitudesMuestrasData.data?.data?.estado_solicitud_id !== 'pendiente_pago_y_entrega'
                                && <div className='color-primary cursor-pointer d-inline-block' onClick={handleClickCambiarABorrador}>
                                    <small><FontAwesomeIcon icon={faArrowLeft} className='me-1' /> Tornar a mostres</small>
                                </div>
                            }

                        </div>
                        <div className="col-md-9 col-lg-7 col-xl-6">
                            {
                                (fetchMisDatosData.loading || fetchPreciosData.loading || fetchSolicitudesMuestrasData.loading) ? <Loader /> : <>

                                    <div className="card card px-3 py-2">
                                        <div>
                                            <div className="d-block py-2">
                                                <p className='d-inline-block ms-0 mb-0'>{formFacturacion.nombre}</p>
                                            </div>
                                            <div className="d-inline-block me-3 py-2">
                                                <p className=' d-inline-block mb-0'>{formFacturacion.documento}</p>
                                            </div>

                                            <div className="line-dashed"></div>

                                            <div className="d-flex justify-content-between align-items-center py-2">
                                                <h5 className='h6 d-inline-block mb-0'>Preu unitari per mostra (IVA inclòs):</h5>
                                                <span className='d-inline-block ms-2 mb-0'>{precio} €</span>
                                            </div>

                                            <div className="d-flex justify-content-between align-items-center py-2">
                                                <h5 className='h6 d-inline-block mb-0'>Nombre de mostres: </h5>
                                                <span className='d-inline-block ms-2 mb-0'>{muestras.length}</span>
                                            </div>

                                            <div className="line-dashed"></div>

                                            <div className="d-flex justify-content-end align-items-center py-2">
                                                <h5 className='h6 d-inline-block mb-0'>Import total a pagar: </h5>
                                                <span className='d-inline-block ms-2 mb-0'>{(muestras.length * Number(precio)).toFixed(2)} €</span>
                                            </div>

                                        </div>
                                    </div>
                                    <div>
                                        <div className="my-3 col-12 border-bottom pb-2">
                                            <p className='mb-1'>Seleccioneu una via de pagament:</p>
                                            {
                                                !fetchCambiarPagadorPOSTData.loading && !fetchPreciosData.loading && <InputRadios
                                                    name='via_pago'
                                                    onChange={handleInputChangeGenerarFactura}
                                                    validation={valuesValidGenerarFactura}
                                                    fetchData={fetchGenerarFacturaData}
                                                    values={valuesViaPago || []}
                                                    // value={(!fetchMisDatosData.data?.data?.usuario_num_colegiado || !fetchCambiarPagadorPOSTData?.data?.data?.domiciliacion_disponible) 
                                                    //     ? 'visa' : 'domiciliacion'}
                                                    value={formGenerarFactura.via_pago}
                                                // disabled={(!fetchMisDatosData.data?.data?.usuario_num_colegiado || !fetchCambiarPagadorPOSTData?.data?.data?.domiciliacion_disponible)}
                                                />
                                            }
                                        </div>
                                    </div>
                                    <div className='d-flex flex-wrap justify-content-between mt-3 mb-2'>
                                        <Button variant="outline-primary" onClick={handleShowModalCambiarPagador} className='px-3'>
                                            Vull canviar el pagador de la factura
                                        </Button>
                                        <ButtonSpinner
                                            variant="primary" type="submit" className="ms-3 px-3"
                                            handleSubmit={handleMiddlePagar}
                                            fetchDataLoading={[fetchGenerarFacturaData.loading, fetchPagarData.loading]}
                                            value='Pagar'
                                        />

                                    </div>

                                    {
                                        fetchMisDatosData.data?.data?.usuario_num_colegiado &&
                                        <div className='mt-3'>
                                            <Alert variant='info' className='py-1'>
                                                <small>
                                                    Si canvies el pagador a empresa, has de marcar pagament en visa. Només es pot fer domiciliació als col·legiats
                                                </small>
                                            </Alert>
                                        </div>
                                    }

                                    <div ref={inputEl} dangerouslySetInnerHTML={{ __html: dataHtml }}></div>

                                    <div>
                                        {
                                            !fetchMisDatosData.data?.data?.usuario_num_colegiado &&
                                            <small className='text-decoration-underline cursor-pointer' onClick={handleShowModalPagarPresencialmente}>
                                                Pagar presencialment (únicament per a transferències)
                                            </small>
                                        }

                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showModalCambiarPagador} onHide={handleCloseModalCambiarPagador} size=''>
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title className='h5'></Modal.Title>
                </Modal.Header>
                <Modal.Body className='pb-4'>
                    <div className="container">
                        <div className="row justify-content-center flex-column align-items-center">
                            <div className="col-12 mb-4">
                                <h5 className='text-center'>Vull canviar el pagador de la factura</h5>
                            </div>

                            <div className="col-lg-10 mb-4">
                                <FloatingLabel
                                    controlId="floatingSelect"
                                    label="Pagador*"
                                    onChange={handleChangePagador}
                                >
                                    <Form.Select aria-label="Floating label select example">
                                        <option></option>
                                        <option value="1">Les meves dades personals</option>
                                        <option value="2">A una empresa</option>
                                        <option value="3">A una persona</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </div>
                            {
                                pagador === 1 && <>
                                    <div className="mb-1 col-lg-10 mt-0">
                                        <div className="d-flex align-items-center">
                                            <ButtonSpinner
                                                variant="primary" type="submit" className="mx-auto px-3"
                                                handleSubmit={handleChangeAMisDatosFacturacion} fetchDataLoading={[fetchCambiarPagadorData.loading]}
                                                value='Desar'
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                            {
                                pagador === 2 && <>
                                    <div className="mb-4 col-lg-10 mt-2">
                                        <div className="d-flex align-items-center">
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="CIF *"
                                                className='flex-grow-1'
                                            >
                                                <Form.Control
                                                    type="text"
                                                    name='nif'
                                                    onChange={(e) => setDocumento(e.target.value)}
                                                    placeholder="nif"
                                                />
                                            </FloatingLabel>
                                            <ButtonSpinner
                                                variant="primary" type="submit" className="ms-3 px-3"
                                                handleSubmit={handleCambiarPagador} fetchDataLoading={[fetchCambiarPagadorData.loading]}
                                                value='' icon={<FontAwesomeIcon icon={faSearch} />}
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                            {
                                pagador === 3 && <>
                                    <div className="mb-4 col-lg-10 mt-2">
                                        <div className="d-flex align-items-center">
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="NIF *"
                                                className='flex-grow-1'
                                            >
                                                <Form.Control
                                                    type="text"
                                                    name='nif'
                                                    placeholder="nif"
                                                    onChange={(e) => setDocumento(e.target.value)}
                                                />
                                            </FloatingLabel>
                                            <ButtonSpinner
                                                variant="primary" type="submit" className="ms-3 px-3"
                                                handleSubmit={handleCambiarPagador} fetchDataLoading={[fetchCambiarPagadorData.loading]}
                                                value='' icon={<FontAwesomeIcon icon={faSearch} />}
                                            />
                                        </div>
                                    </div>
                                </>
                            }

                        </div>

                    </div>
                </Modal.Body>

            </Modal>

            <ModalCambiarPagador
                showModalNuevoPagador={showModalNuevoPagador}
                handleCloseModalNuevoPagador={handleCloseModalNuevoPagador}
                handleShowModalNuevoPagador={handleShowModalNuevoPagador}
                fetchParametrosData={fetchParametrosData}
                solicitud_id={solicitud_id}
                documento={documento}
                pagador={pagador}
                setMuestras={setMuestras}
                fetchSolicitudesMuestras={fetchSolicitudesMuestras}
            />

            <ModalPagarPresencialmente
                showModalPagarPresencialmente={showModalPagarPresencialmente}
                handleCloseModalPagarPresencialmente={handleCloseModalPagarPresencialmente}
                handleShowModalPagarPresencialmente={handleShowModalPagarPresencialmente}
                fetchParametrosData={fetchParametrosData}
                solicitud_id={solicitud_id}
                documento={documento}
                pagador={pagador}
                fetchSolicitudesMuestras={fetchSolicitudesMuestras}
                setIndexFocusWizard={setIndexFocusWizard}
                indexFocusWizard={indexFocusWizard}
            />

            {/* <ModalShowStatus fechData={fetchCambiarPagadorData} /> */}

        </>
    )
}
