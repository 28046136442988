import React from 'react'
import { Form } from 'react-bootstrap'

export const MsgInputServer = ({ name, obj, className = '' }) => {

    return (
        <>
            {
                obj?.status === 'Error' && obj?.data?.[name]?.map((elem, index) => {
                    return (
                        <Form.Text key={index} className={`text-danger d-block ${className}`}>{elem}</Form.Text>
                    )
                })
            }
            {
                obj?.status === 'Error' && !name && !obj?.data && 
                <Form.Text className={`text-danger d-block ${className}`}>{obj?.message}</Form.Text>
            }
        </>

    )
}
