import React, { useEffect, useState } from 'react'

import './Sollicitud.css'
import Wizard from '../../components/Wizard/Wizard';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Footer } from '../../components/Footer/Footer'
import { useFetch } from '../../hooks/useFetch';
import { useHistory } from 'react-router';
import { DadesMostres } from './Wizard/Seccion2/DadesMostres';
// import { TipusTest } from './Wizard/Seccion1/TipusTest';
import { FacturacionPago } from './Wizard/Seccion3/FacturacionPago';
import { EntregaMuestras } from './Wizard/Seccion4/EntregaMuestras';
import { ModalEliminarSolicitud } from './ModalEliminarSolicitud';
import { Loader } from '../../components/Loader/Loader';

export const Sollicitud = ({ match }) => {

    // Wizard

    const listWizard = [
        // 'Tipus de test',
        'Dades mostres',
        'Facturació i pagament',
        'Lliurament de mostres',
    ]

    const [indexFocusWizard, setIndexFocusWizard] = useState(0);

    const [solicitud_id, setSolicitud_id] = useState(match?.params?.id);

    const [tipoTest, setTipoTest] = useState('aluminosis');

    let history = useHistory();

    const [fetchParametros, fetchParametrosData] = useFetch('GET', `/parametros`)

    const [fetchParametrosPoblaciones, fetchParametrosDataPoblaciones] = useFetch('GET', `/parametros`)

    const [fetchEditarEstadoSolicitud, fetchEditarEstadoSolicitudData] = useFetch('PATCH', `/clientes/solicitudes`)

    const [fetchSolicitudesMuestras, fetchSolicitudesMuestrasData] = useFetch('GET', `/clientes/solicitudes`)

    const [fetchSolicitudGeneral, fetchSolicitudGeneralData] = useFetch('GET', `/clientes/solicitudes`)

    const [muestras, setMuestras] = useState([]);

    // Se ejecuta al inicio del componente

    useEffect(() => {

        fetchParametros()
        fetchParametrosPoblaciones()

        if (solicitud_id) {
            console.log('fetchSolicitudGeneral 1 vez')
            fetchSolicitudGeneral({
                id: solicitud_id
            })
        }

    }, [])

    useEffect(() => {

        if (solicitud_id) {

            fetchSolicitudesMuestras({
                id: solicitud_id
            })
        }

    }, [solicitud_id])

    // useEffect(() => {

    //     if (fetchEditarEstadoSolicitudData.data) {

    //         console.log('fetchSolicitudesMuestras en Solicitud')
    //         fetchSolicitudesMuestras({
    //             id: solicitud_id
    //         })
    //     }

    // }, [fetchEditarEstadoSolicitudData])

    useEffect(() => {

        console.log('fetchSolicitudesMuestrasData')
        if (fetchSolicitudesMuestrasData.data) {
            if (fetchSolicitudesMuestrasData.data.status === 'Success') {

                setMuestras(fetchSolicitudesMuestrasData.data?.data?.muestras)

                const estado_solicitud_id = fetchSolicitudesMuestrasData.data?.data?.estado_solicitud_id
    
                if ( estado_solicitud_id === 'borrador')
                    setIndexFocusWizard(0)
    
                if (estado_solicitud_id === 'pendiente_pago')
                    setIndexFocusWizard(1)
    
                if (['pendiente_entrega', 'pendiente_pago_y_entrega', 'pendiente_analisis', 'analisis_finalizado'].find(item => item === estado_solicitud_id))
                    setIndexFocusWizard(2)

            } else {
    
                history.push(`/sollicitud`)
            }
        }

    }, [fetchSolicitudesMuestrasData])

    useEffect(() => {

        console.log('fetchSolicitudGeneralData')

        if (fetchSolicitudGeneralData.data) {
            if (fetchSolicitudGeneralData.data.status === 'Success') {

                setMuestras(fetchSolicitudGeneralData.data?.data?.muestras)
    
                const estado_solicitud_id = fetchSolicitudGeneralData.data?.data?.estado_solicitud_id
    
                if ( estado_solicitud_id === 'borrador')
                    setIndexFocusWizard(0)
    
                if (estado_solicitud_id === 'pendiente_pago')
                    setIndexFocusWizard(1)
    
                if (['pendiente_entrega', 'pendiente_pago_y_entrega', 'pendiente_analisis', 'analisis_finalizado'].find(item => item === estado_solicitud_id))
                    setIndexFocusWizard(2)
    
            } else {
    
                history.push(`/sollicitud`)
            }
        }
        

    }, [fetchSolicitudGeneralData])
    

    const handleEditarEstadoSolicitud = (estado) => {
        fetchEditarEstadoSolicitud({
            id: `${solicitud_id}/estado`,
            body: {
                estado_id: estado
            }
        })
    }

    // Modal Eliminar solicitud

    const [showModalEliminarSolicitud, setShowModalEliminarSolicitud] = useState(false);

    const handleCloseModalEliminarSolicitud = () => setShowModalEliminarSolicitud(false);

    const handleShowModalEliminarSolicitud = () => {
        setShowModalEliminarSolicitud(true)
    }

    return (
        <>
            <div className="MainInner-wizard shadow-sm animate__animated animate__fadeInUp d-flex flex-column w-auto mt-4 mb-5 mx-4">
                <h5 className='mt-2 p-3 pb-1 text-center'>Sol·licitud de prova de Test aluminós {solicitud_id}</h5>
                {
                    (solicitud_id && fetchSolicitudGeneralData.loading) ? <Loader /> : <>

                        <Wizard>
                            <Wizard.Header
                                listWizard={listWizard}
                                indexFocusWizard={indexFocusWizard}
                            // setIndexFocusWizard={setIndexFocusWizard}
                            />
                            <Wizard.Body indexFocusWizard={indexFocusWizard}>
                                {/* <section>
                                        <TipusTest
                                            setIndexFocusWizard={setIndexFocusWizard}
                                            fetchParametrosData={fetchParametrosData}
                                            setTipoTest={setTipoTest}
                                            fetchSolicitudesMuestrasData={fetchSolicitudesMuestrasData}
                                        />
                                    </section> */}

                                <section>
                                    <DadesMostres
                                        muestras={muestras}
                                        setMuestras={setMuestras}
                                        solicitud_id={solicitud_id}
                                        setSolicitud_id={setSolicitud_id}
                                        fetchSolicitudesMuestras={fetchSolicitudesMuestras}
                                        fetchSolicitudesMuestrasData={fetchSolicitudesMuestrasData}
                                        tipoTest={tipoTest}
                                        indexFocusWizard={indexFocusWizard}
                                        setIndexFocusWizard={setIndexFocusWizard}
                                        fetchParametrosData={fetchParametrosData}
                                        handleEditarEstadoSolicitud={handleEditarEstadoSolicitud}
                                        fetchParametrosDataPoblaciones={fetchParametrosDataPoblaciones}
                                        fetchEditarEstadoSolicitudData={fetchEditarEstadoSolicitudData}
                                    />
                                </section>

                                <section>
                                    <FacturacionPago
                                        setIndexFocusWizard={setIndexFocusWizard}
                                        muestras={muestras}
                                        setMuestras={setMuestras}
                                        solicitud_id={solicitud_id}
                                        indexFocusWizard={indexFocusWizard}
                                        fetchSolicitudesMuestras={fetchSolicitudesMuestras}
                                        fetchSolicitudesMuestrasData={fetchSolicitudesMuestrasData}
                                        fetchParametrosData={fetchParametrosData}

                                    />
                                </section>

                                <section>
                                    <EntregaMuestras
                                        indexFocusWizard={indexFocusWizard}
                                        setIndexFocusWizard={setIndexFocusWizard}
                                        muestras={muestras}
                                        setMuestras={setMuestras}
                                        solicitud_id={solicitud_id}
                                        fetchSolicitudesMuestras={fetchSolicitudesMuestras}
                                        fetchSolicitudesMuestrasData={fetchSolicitudesMuestrasData}
                                        fetchParametrosData={fetchParametrosData}
                                    />
                                </section>
                            </Wizard.Body>
                        </Wizard>
                        {
                            solicitud_id && ['borrador', 'pendiente_pago'].find(item => item === fetchSolicitudesMuestrasData.data?.data?.estado_solicitud_id) && <div className='p-3 border-top'>
                                <Button variant="default" size='sm' className='ms-3 px-3' onClick={handleShowModalEliminarSolicitud}>
                                    <FontAwesomeIcon icon={faTrash} className='me-2' /> Eliminar Sol·licitud
                                </Button>
                            </div>
                        }

                    </>
                }
            </div>

            <div className="mt-auto">
                <Footer />
            </div>

            <ModalEliminarSolicitud
                showModalEliminarSolicitud={showModalEliminarSolicitud}
                handleCloseModalEliminarSolicitud={handleCloseModalEliminarSolicitud}
                solicitud_id={solicitud_id}
            />
        </>
    )
}
