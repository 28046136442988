import React from 'react'
import { Form } from 'react-bootstrap'
import { MsgInput } from '../MsgInput/MsgInput'
import { MsgInputServer } from '../MsgInput/MsgInputServer'
import { getMsgInputColor, getMsgInputServerColor } from '../../helpers/helpers';

export const InputTextNormal = ({
    label, className = '', name, type = 'text', value, onChange,
    placeholder, validation={}, fetchData = {}, disabled, as, style, rows
}) => {

    const handleOnchange = (e) => {

        if (validation[name])
            validation[name].required.isOk = true

        if (fetchData.data)
            fetchData.data.status = 'Success'

        onChange(e)
    }

    return (
        <>
            {
                label && <Form.Label>{label}</Form.Label>
            }
            
            <Form.Control
                type={type}
                as={as}
                rows={rows}
                className={`${className} ${(validation ? getMsgInputColor(validation, name) : false) || getMsgInputServerColor(fetchData, name) ? 'is-invalid' : ''}`}
                name={name}
                value={value}
                onChange={handleOnchange}
                placeholder={placeholder}
                disabled={disabled}
                autoComplete='one-time-code'
                style={style}
            />
            {
                validation && <MsgInput obj={validation} name={name} />
            }
            <MsgInputServer obj={fetchData?.data} name={name} />
        </>
    )
}
