import React, { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useFetch } from '../../../../hooks/useFetch'
import { ButtonSpinner } from '../../../../components/ButtonSpinner/ButtonSpinner'

export const ModalPagarPresencialmente = ({ showModalPagarPresencialmente, handleCloseModalPagarPresencialmente, indexFocusWizard, setIndexFocusWizard, solicitud_id, documento, pagador, fetchSolicitudesMuestras }) => {

    const [fetchPagarPresencialmente, fetchPagarPresencialmenteData] = useFetch('POST', `/clientes/solicitudes`)

    const handlePagarPresencialmente = () => {

        fetchPagarPresencialmente({
            id: solicitud_id + '/pagar_presencialmente'
        })
    }

    useEffect(() => {

        if (fetchPagarPresencialmenteData.data?.status === 'Success') {

            handleCloseModalPagarPresencialmente()
            // fetchSolicitudesMuestras()
            setIndexFocusWizard(indexFocusWizard + 1)
        }

    }, [fetchPagarPresencialmenteData])

    return (
        <>
            <Modal show={showModalPagarPresencialmente} onHide={handleCloseModalPagarPresencialmente} size=''>
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title className='h5'></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 mb-2">
                                <h5 className='text-center'>Esteu segur que voleu pagar presencialment?</h5>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='border-0'>
                    <Button variant="default" onClick={handleCloseModalPagarPresencialmente}>
                        Sortir
                    </Button>
                    <ButtonSpinner
                        variant="primary" type="submit" className="px-4 ms-2"
                        handleSubmit={handlePagarPresencialmente} fetchDataLoading={[fetchPagarPresencialmenteData.loading]}
                        value='Continuar'
                    />
                </Modal.Footer>
            </Modal>
        </>
    )
}
