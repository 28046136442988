import { useState } from 'react';

export const useValidator = (initialState) => {

    const [valuesValid, setvaluesValid] = useState(initialState)

    const handleVerifiedValues = (objValues) => {

        const valuesValidTemp = { ...valuesValid }

        for (const key in valuesValidTemp) {

            if (valuesValidTemp[key].required) {
                if (objValues[key] !== undefined && objValues[key] !== null && objValues[key] !== '') {

                    valuesValidTemp[key]['required']['isOk'] = true

                } else {

                    valuesValidTemp[key]['required']['isOk'] = false
                }
            }
        }

        console.log(valuesValidTemp)
        
        setvaluesValid({
            ...valuesValidTemp
        })

        const objParse = JSON.stringify(valuesValidTemp)

        if (objParse.indexOf('"isOk":false') === -1)
            return true

        return false
    }

    const resetValuesValid = () => {

        const valuesValidTemp = { ...valuesValid }

        for (const key in valuesValidTemp) {

            if (valuesValidTemp[key].required) {

                valuesValidTemp[key]['required']['isOk'] = true
            }
        }

        setvaluesValid({
            ...valuesValidTemp
        })
    }

    return [valuesValid, handleVerifiedValues, resetValuesValid]

}