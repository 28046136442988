import { faArrowLeft, faPencilAlt, faTicketAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Alert, Button, OverlayTrigger, Table, Tooltip } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { Loader } from '../../../../components/Loader/Loader'
import { useFetch } from '../../../../hooks/useFetch'
import { useLang } from '../../../../language'
import { ModalEditarMuestra } from '../Seccion2/ModalEditarMuestra'

export const EntregaMuestras = ({ setIndexFocusWizard, indexFocusWizard, muestras, setMuestras, solicitud_id,
    fetchSolicitudesMuestrasData, fetchSolicitudesMuestras, fetchParametrosData }) => {

    const history = useHistory()

    const Lang = useLang()

    useEffect(() => {

        setMuestras([])
        if (solicitud_id) {
            console.log('fetchSolicitudesMuestras')
            fetchSolicitudesMuestras({
                id: solicitud_id
            })
        }

    }, [])


    // Modal de editar muestra

    const [showModalEditarMuestra, setShowModalEditarMuestra] = useState({
        value: false,
        id: -1
    });

    const handleCloseModalEditarMuestra = () => setShowModalEditarMuestra({
        ...showModalEditarMuestra,
        value: false,
    });

    const handleShowModalEditarMuestra = (id) => {
        setShowModalEditarMuestra({
            value: true,
            id
        })
    };

    return (
        <>

            <div className="container animate__faster animate__animated animate__fadeInLeft">
                <div className="py-3">
                    <div className="">
                        {
                            fetchSolicitudesMuestrasData.data?.data.estado_solicitud_id === 'pendiente_pago_y_entrega' && <div className='color-primary cursor-pointer tornar-facturacio pb-3' onClick={() => setIndexFocusWizard(indexFocusWizard - 1)}>
                                <small><FontAwesomeIcon icon={faArrowLeft} className='me-1' /> Tornar a Facturació i pagament</small>
                            </div>
                        }

                        <h5 className='mb-4 text-center'>Lliurament de mostres</h5>
                    </div>

                    <div className="mb-3">
                        <div className="d-flex">
                            <h5 className='mb-0 text-center h6'>Mostres</h5>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-8">

                            <Table bordered responsive hover className='tableMain'>
                                <thead>
                                    <tr>
                                        <th>{Lang('NUMERO_DE_LA_MUESTRA')}</th>
                                        <th>Adreça</th>
                                        <th>Pis</th>
                                        <th>Porta</th>
                                        <th>Localització de la bigueta</th>
                                        <th>Estat</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        muestras.map(item => {

                                            return (
                                                <tr key={item.id}>
                                                    <td valign='middle'>{item.numero}</td>
                                                    <td valign='middle'>{item.direccion}</td>
                                                    <td valign='middle'>{item.piso}</td>
                                                    <td valign='middle'>{item.puerta}</td>
                                                    <td valign='middle'>{fetchParametrosData?.data?.data?.localizaciones_vigueta?.find(item2 => item2.id === item.localizacion_vigueta)?.nombre}</td>
                                                    <td valign='middle'>{item.estado}</td>
                                                    <td valign='middle' className='shrink-td'>
                                                        {
                                                            fetchSolicitudesMuestrasData.data?.data.estado_solicitud_id !== 'pendiente_analisis' &&
                                                            fetchSolicitudesMuestrasData.data?.data.estado_solicitud_id !== 'analisis_finalizado' &&
                                                            <>
                                                                <OverlayTrigger overlay={<Tooltip id={`tooltip-ver-muestras-${item.solicitud_id}`}>Veure mostres</Tooltip>}>
                                                                    <span className="d-inline-block">
                                                                        <Button variant="dark" size='sm' className='' onClick={() => handleShowModalEditarMuestra(item.id)}>
                                                                            <FontAwesomeIcon icon={faPencilAlt} />
                                                                        </Button>
                                                                    </span>
                                                                </OverlayTrigger>
                                                                <a
                                                                    className="btn btn-success btn-sm ms-3"
                                                                    id="download-file"
                                                                    href={item.ticket_link}
                                                                    target='_blank'
                                                                    rel="noopener noreferrer">
                                                                    <FontAwesomeIcon icon={faTicketAlt} />
                                                                </a>
                                                            </>
                                                        }

                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    {
                                        fetchSolicitudesMuestrasData?.loading &&
                                        <tr>
                                            <td colSpan={7} className="text-center ResultadoTest__TdLoader">
                                                <Loader />
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                            {
                                fetchSolicitudesMuestrasData.data?.data.estado_solicitud_id !== 'pendiente_analisis' &&
                                fetchSolicitudesMuestrasData.data?.data.estado_solicitud_id !== 'analisis_finalizado' &&
                                <div className="text-center mt-2">
                                    <a
                                        className="btn btn-success ms-3 px-3"
                                        id="download-file"
                                        href={fetchSolicitudesMuestrasData?.data?.data?.tickets_link}
                                        target='_blank'
                                        rel="noopener noreferrer">
                                        <FontAwesomeIcon icon={faTicketAlt} className='me-2' /> Descarrega les etiquetes
                                    </a>
                                    <Button variant="primary" className='ms-3' onClick={() => history.push('/historial-resultats')}>
                                        Procés finalitzat
                                    </Button>
                                </div>
                            }

                        </div>

                        <div className="col-lg-4 mt-5 mt-lg-0">
                            <Alert variant='success'>
                                <p>
                                    Les mostres han d'estar correctament identificades amb l'etiqueta que has d'imprimir en el botó "Descarrega les etiquetes". Si no tens impressora, apunta el número assignat en cada sobre o bossa de la mostra.
                                </p>
                                <p>
                                    Has de lliurar les mostres al carrer Bon pastor, 5 (Barcelona)
                                    És possible lliurar-les a les nostres <a href="https://www.cateb.cat/" target='_blank' rel="noopener noreferrer" className='color-primary'>delegacions</a>. Consulta les freqüències de recollida.
                                </p>
                                <p className='mb-0'>
                                    Si tens algun dubte pots posar-te en contacte amb nosaltres al telf. 932 40 20 60 consultoriatecnica@cateb.cat
                                </p>
                            </Alert>

                        </div>
                    </div>

                </div>
            </div>
            <ModalEditarMuestra
                showModalEditarMuestra={showModalEditarMuestra}
                handleCloseModalEditarMuestra={handleCloseModalEditarMuestra}
                // handleShowModalCopiarDatos={handleShowModalCopiarDatos}
                fetchParametrosData={fetchParametrosData}
                muestras={muestras}
                solicitud_id={solicitud_id}
                fetchSolicitudesMuestras={fetchSolicitudesMuestras}
                setMuestras={setMuestras}
            />
        </>
    )
}
