import React, { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { ButtonSpinner } from '../../../../components/ButtonSpinner/ButtonSpinner'
import { useFetch } from '../../../../hooks/useFetch'

export const ModalEliminarMuestra = ({ showModalEliminarMuestra, handleCloseModalEliminarMuestra, solicitud_id, fetchSolicitudesMuestras, setMuestras }) => {

    const [fetchEliminarMuestra, fetchEliminarMuestraData] = useFetch('DELETE', `/clientes/muestras`)

    const [fetchAnularMuestra, fetchAnularMuestraData] = useFetch('PATCH', `/clientes/muestras`)

    const handleEliminarMuestra = () => {

        if (showModalEliminarMuestra.ticket_link) {

            fetchAnularMuestra({
                id: showModalEliminarMuestra.id + '/estado',
                body: {
                    estado_id: 'anulada'
                }
            })
        } else {

            fetchEliminarMuestra({
                id: showModalEliminarMuestra.id
            })
        }
    }

    useEffect(() => {

        if (fetchEliminarMuestraData.data?.status === 'Success' || fetchAnularMuestraData.data?.status === 'Success') {

            if (solicitud_id) {
                fetchSolicitudesMuestras({
                    id: solicitud_id
                })
            }
            setMuestras([])
            handleCloseModalEliminarMuestra()
        }

    }, [fetchEliminarMuestraData.data, fetchAnularMuestraData.data])

    return (
        <>
            <Modal show={showModalEliminarMuestra.value} onHide={handleCloseModalEliminarMuestra} size='md' id='ModalEliminarMuestra'>
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title className='h5'></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <h5 className='text-center'>
                                    {
                                        showModalEliminarMuestra.ticket_link ? 
                                        `Segur que voleu anul·lar la mostra ${showModalEliminarMuestra.id}?` :
                                        `Segur que voleu eliminar la mostra ${showModalEliminarMuestra.id}?`
                                    }
                                    
                                    </h5>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='border-0'>
                    <Button variant="default" onClick={handleCloseModalEliminarMuestra}>
                        No
                    </Button>
                    <ButtonSpinner
                        variant="primary" type="submit" className="ms-2"
                        handleSubmit={handleEliminarMuestra} fetchDataLoading={[fetchEliminarMuestraData.loading, fetchAnularMuestraData.loading]}
                        value='Sí, eliminar'
                    />
                </Modal.Footer>
            </Modal>
        </>
    )
}
