const TipoViasServices = {

    GET: function () {

        return {
            'Accés': 'Accés',
            'Apartaments': 'Apartaments',
            'Autopista': 'Autopista',
            'Autovia': 'Autovia',
            'Avinguda': 'Avinguda',
            'Baixada': 'Baixada',
            'Barranc': 'Barranc',
            'Barri': 'Barri',
            'Blocs': 'Blocs',
            'Calçada': 'Calçada',
            'Camí': 'Camí',
            'Carrer': 'Carrer',
            'Carrerada': 'Carrerada',
            'Carreró': 'Carreró',
            'Carretera': 'Carretera',
            'Cases': 'Cases',
            'Circumval·lació': 'Circumval·lació',
            'Colònia': 'Colònia',
            'Complex': 'Complex',
            'Corraló': 'Corraló',
            'Corredor': 'Corredor',
            'Corriol': 'Corriol',
            'Costa': 'Costa',
            'Dic': 'Dic',
            'Disseminat': 'Disseminat',
            'Drecera': 'Drecera',
            'Eix': 'Eix',
            'Escala': 'Escala',
            'Glorieta': 'Glorieta',
            'Gran Via': 'Gran Via',
            'Grup': 'Grup',
            'Illa': 'Illa',
            'Jardins': 'Jardins',
            'Lloc': 'Lloc',
            'Mirador': 'Mirador',
            'Moll': 'Moll',
            'Muralla': 'Muralla',
            'Paratge': 'Paratge',
            'Parc': 'Parc',
            'Partida': 'Partida',
            'Pas': 'Pas',
            'Passadís': 'Passadís',
            'Passatge': 'Passatge',
            'Passeig': 'Passeig',
            'Passera': 'Passera',
            'Pista': 'Pista',
            'Pla': 'Pla',
            'Plaça': 'Plaça',
            'Placeta': 'Placeta',
            'Platja': 'Platja',
            'Polígon': 'Polígon',
            'Polígon industrial': 'Polígon industrial',
            'Pont': 'Pont',
            'Port': 'Port',
            'Portal': 'Portal',
            'Prolongació': 'Prolongació',
            'Pujada': 'Pujada',
            'Racó': 'Racó',
            'Ramal': 'Ramal',
            'Rambla': 'Rambla',
            'Rasa': 'Rasa',
            'Raval': 'Raval',
            'Raval (barri)': 'Raval (barri)',
            'Rial': 'Rial',
            'Riera': 'Riera',
            'Ronda': 'Ronda',
            'Rotonda': 'Rotonda',
            'Sector': 'Sector',
            'Sender': 'Sender',
            'Torrent': 'Torrent',
            'Trauèssa': 'Trauèssa',
            'Travessera': 'Travessera',
            'Travessia': 'Travessia',
            'Urbanització': 'Urbanització',
            'Veïnat': 'Veïnat',
            'Via': 'Via',
            'Viaducte': 'Viaducte',
            'Vial': 'Vial',
            'Zona': 'Zona',
        }
    }
}

export {
    TipoViasServices,
}