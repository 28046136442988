import React, { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { ButtonSpinner } from '../../../components/ButtonSpinner/ButtonSpinner'
import { MsgInputServer } from '../../../components/MsgInput/MsgInputServer'
import { useFetch } from '../../../hooks/useFetch'

export const ModalEliminarMuestra = ({ showModalEliminarMuestra, handleCloseModalEliminarMuestra, handleShowModalEliminarMuestra, fetchSolicitudes }) => {

    const [fetchEliminarMuestra, fetchEliminarMuestraData] = useFetch('DELETE', `/clientes/solicitudes`)

    const handleEliminarMuestra = () => {
        fetchEliminarMuestra({
            id: showModalEliminarMuestra.id
        })
    }

    useEffect(() => {

        if (fetchEliminarMuestraData.data?.status === 'Success') {

            handleCloseModalEliminarMuestra()
            fetchSolicitudes()
        }

    }, [fetchEliminarMuestraData.data])

    return (
        <>
            <Modal show={showModalEliminarMuestra.value} onHide={handleCloseModalEliminarMuestra} size='md' id='ModalEliminarMuestra'>
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title className='h5'></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <h5 className='text-center'>Segur que suprimiu la mostra {showModalEliminarMuestra.id}?</h5>
                            </div>
                            <div className="col-12">
                                <MsgInputServer obj={fetchEliminarMuestraData.data} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='border-0'>
                    <Button variant="default" onClick={handleCloseModalEliminarMuestra}>
                        No
                    </Button>
                    <ButtonSpinner
                        variant="primary" type="submit" className="ms-2"
                        handleSubmit={handleEliminarMuestra} fetchDataLoading={[fetchEliminarMuestraData.loading]}
                        value='Sí, eliminar'
                    />
                </Modal.Footer>
            </Modal>
        </>
    )
}
