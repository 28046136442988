import React from 'react'
import { Button, Spinner } from 'react-bootstrap'

export const ButtonSpinner = ({ variant, type, className = '', handleSubmit, fetchDataLoading = [], 
    value, size, icon = '', disabled = false }) => {

    const isLoading = fetchDataLoading.filter((item) => item === true).length > 0
    // console.log(fetchDataLoading, value)
    
    return (
        <>
            <Button
                variant={variant} type={type} size={size} 
                className={className} onClick={handleSubmit} disabled={isLoading || disabled}>
                {
                    isLoading ?
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className='me-1'
                        />
                        : ''
                } {icon} {value}
            </Button>
        </>
    )
}
