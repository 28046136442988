import React from 'react';
import './Footer.css'

export const Footer = ({ style }) => {
  return (
    <section id='Footer' className='d-flex flex-column align-items-center flex-sm-row justify-content-between px-3' style={style}>
      <div className='pt-2 py-sm-2'>
        © {(new Date()).getFullYear()} Cateb
      </div>
      <div className='d-flex flex-column align-items-center flex-sm-row '>
        <span className='ms-sm-3 py-2'>
          <a href="https://www.cateb.cat/avis-legal-i-politica-de-privacitat/" target='_blank' rel="noopener noreferrer">Avís legal</a>
          <a href="https://www.cateb.cat/avis-legal-i-politica-de-privacitat/#cookies" target='_blank' rel="noopener noreferrer">Privacitat i Cookies</a>
        </span>
      </div>
    </section>
  );
};
