const lang = {
    CA: {
        INICIAR_SESION: "Iniciar sessió",
        INSCRIPCION: "Inscripció",
        OLVIDASTE_CONTRASENA: "Has oblidat la teva contrasenya?",
        AVISO_LEGAL: "Avís Legal",
        POLITICA_PRIVACIDAD: "Política de Privacitat",
        POLITICA_COOKIES: "Política de Cookies",
        ESPANIOL: "Espanyol",
        CATALAN: "Català",
        CONTRASENIA: "Contrasenya",
        PROCESOS: "Processos",
        PROCESO: "Procés",
        CAMBIAR_CONTRASENIA: "Canviar contrasenya",
        SALIR: "Sortir",
        NUM: "Núm",
        LISTA_DE: "Llista de",
        FILTRO: "Filtre",
        BUSCAR: "Cercar",
        PLANTILLAS_MENSAJES: "Plantilles Missatges",
        VER_MAS: "Veure més",
        NOMBRE_DEL_TRAMITADOR: 'Nom del tramitador',
        DESDE: "Des",
        HASTA: "Fins",
        ESTADO: "Estat",
        FECHA_ESTADO: "Data estat",
        AVISAR_PENDIENTES_FIRMAR: "Avisar pendents de signar",
        DATOS_GENERALES: "Dades generals",
        HISTORICO: "Històric",
        PLANTILLAS: "Plantilles",
        DESCRIPCION_PLANTILLA: "Descripció de la plantilla",
        REMITENTE: "Remitent",
        CORREO_REMITENTE: "Correu del remitent",
        ASUNTO: "Assumpte",
        MENSAJE: "Missatge",
        CODIGOS_ADICIONALES: "Codis addicionals",
        PERTENECE_PROCESO_DE: "Pertany al procés de",
        TRAMITADOR: "Tramitador",
        TAMANIO: "Mida",
        FORMATO: "Format",
        DESCARGAR: "Descarregar",
        DOCUMENTO: "Document",
        USUARIOS: "Usuaris",
        LOGS: "Logs",
        MANTENIMIENTO: "Manteniment",
        ACTUALIZAR: "Actualitzar",
        COPYRIGHT: "© Col·legi d'Aparelladors, Arquitectes Tècnics i Enginyers d'Edificació de Barcelona",
        ID_INTERNO_PROCESO: "ID",
        NIF_TRAMITADOR: "NIF Tramitador",
        FINALIZADO: "Finalitzat",
        FECHA_MODIFICACION: "Data modificació",
        NUMERO_DE_LA_MUESTRA: 'Núm. de la mostra',

    }
}

export default lang;