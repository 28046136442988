import React, { useContext } from 'react'
import { NavLinksInner } from '../NavLinksInner/NavLinksInner';
import { AuthContext } from '../../auth/AuthContext';
import { useLang } from '../../language';
import { types } from '../../types/types';

import './NavStartInner.css'

export const NavStartInner = ({ setShow, show }) => {

    const { user, dispatch } = useContext(AuthContext);

    // Cambiar idioma
    const Lang = useLang();
    const handdleChangeLanguage = (lang) => {

        dispatch({
            type: types.login,
            payload: {
                ...user,
                lang
            }
        })
    }

    return (
        <section className={`NavStartInner position-fixed d-flex flex-column ${show ? 'toggle-menu' : ''}`} >
            <NavLinksInner />

            <footer className="px-3 mb-3 mt-auto">
                <small>
                    <p className='mb-1'>Tel. 932 40 20 60</p>
                    <p className='mb-1'>consultoriatecnica@cateb.cat</p>
                </small>
            </footer>
        </section>
    )
}
